<template>
  <label
    :for="labelFor"
    class="form-label"
  >
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    labelFor: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$color-label: #607D8A;

.form-label {
  color: $color-label;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
